import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet, Button } from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';

import { v4 as uuidv4 } from 'uuid';

import axios from 'react-native-axios';

import { useApp } from '../components/appContext';

import Spinner from 'react-native-loading-spinner-overlay';

function ScannerScreen(props) {
	const [hasPermission, setHasPermission] = useState(null);
	const [scanned, setScanned] = useState(false);

	const [spinner, setSpinner] = useState(false);
	const [submitStatus, setSubmitStatus] = useState('');
	const [gameCode, setGameCode] = React.useState('');

	const { user, setUser } = useApp();
	const token = user?.token;

	const askForCameraPermission = () => {
		(async () => {
			const { status } = await BarCodeScanner.requestPermissionsAsync();
			setHasPermission(status === 'granted');
		})();
	};

	// Request Camera Permission
	useEffect(() => {
		askForCameraPermission();
	}, []);

	// What happens when we scan the bar code
	const handleBarCodeScanned = ({ type, data }) => {
		setScanned(true);
		setSpinner(true);
		setSubmitStatus('Loading...');
		setGameCode(data);
		setTimeout(() => {
			setSubmitStatus('Join game');
			setTimeout(() => {
				setSpinner(false);
				props.navigation.navigate('GameNav', { screen: 'MainScreen', params: { gamecode: data } });
			}, 500);
		}, 500);
	};

	// Check permissions and return the screens
	if (hasPermission === null) {
		return (
			<View style={styles.container}>
				<Text>Requesting for camera permission</Text>
			</View>
		);
	}
	if (hasPermission === false) {
		return (
			<View style={styles.container}>
				<Text style={{ margin: 10 }}>No access to camera</Text>
				<Button title={'Allow Camera'} onPress={() => askForCameraPermission()} />
			</View>
		);
	}

	return (
		<View style={styles.container}>
			<Spinner visible={spinner} textContent={submitStatus} textStyle={styles.spinnerTextStyle} />
			<View style={styles.barcodebox}>
				<BarCodeScanner
					onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
					style={{ height: 400, width: 400 }}
				/>
			</View>
			<View style={styles.barcodeInputContainer}>
				<Text style={styles.maintext}>{scanned ? '' : 'Scanning...'}</Text>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	spinnerTextStyle: {
		color: '#FFF'
	},
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		marginTop: '5%'
		// justifyContent: 'center',
	},
	maintext: {
		fontSize: 16,
		margin: 20
	},
	addButton: {
		color: 'green',
		marginLeft: 16
	},
	removeButton: {
		color: 'red',
		alignSelf: 'flex-end',
		marginRight: 16
	},
	item: {
		marginVertical: 8,
		marginHorizontal: 16,
		backgroundColor: '#FDD7E4',
		width: '100%',
		alignSelf: 'center'
	},
	barcodebox: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 300,
		width: 300,
		overflow: 'hidden',
		borderRadius: 30,
		backgroundColor: 'tomato'
	},

	barcodeInputContainer: {
		// flex: 1,
		flexDirection: 'row',
		// alignItems: 'center',
		justifyContent: 'flex-start'
	},

	buttonsContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttonContainer: {
		flex: 1
	}
});

export default ScannerScreen;
