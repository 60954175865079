import * as React from 'react';

const AppContext = React.createContext();

function AppProvider({ children }) {
	const [app, setAppValues] = React.useState(null);
	const [settings, setSettingsValues] = React.useState(null);
	const [user, setUserValues] = React.useState(null);

	const setApp = (val) => {
		// localStorage.setItem('app', JSON.stringify(val));
		setAppValues({ ...val });
	};

	const setSettings = (val) => {
		// localStorage.setItem('settings', JSON.stringify(val));
		setSettingsValues(val);
	};
	const setUser = (val) => {
		// localStorage.setItem('user', JSON.stringify(val));
		setUserValues(val);
	};

	const value = { app, setApp, settings, setSettings: setSettings, user, setUser };
	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
function useApp() {
	const context = React.useContext(AppContext);
	if (context === undefined) {
		throw new Error('useApp must be used within a AppProvider');
	}
	return context;
}
export { AppProvider, useApp };
