import React, { useState, useEffect } from 'react';
import {
	View,
	StyleSheet,
	StatusBar,
	TextInput,
	Text,
	Button,
	Image,
	ImageBackground,
	Dimensions,
	TouchableOpacity,
	Alert,
	Modal,
	Pressable,
	ActivityIndicator
} from 'react-native';
import { useWindowDimensions } from 'react-native';
import { useTheme } from '@react-navigation/native';

// Connection
import axios from 'react-native-axios';
import socket from '../../components/socket';


import Spinner from 'react-native-loading-spinner-overlay';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCamera, faQuestionCircle, faList } from '@fortawesome/free-solid-svg-icons';

//App libraries
var config = require('./../../config');
import { useApp } from '../../components/appContext';

// Modals
import ClueModal from './components/ClueModal';
import InstructionsModal from './components/InstructionsModal';
import WelcomeModal from './components/WelcomeModal';
import MapModal from './components/MapModal';
import TaskModal from './components/TaskModal';


const MainScreen = ({ navigation, ...props }) => {
	const { width, height } = useWindowDimensions();
	const { app, setApp } = useApp();
	const gamecode = app?.gamecode;

	const theme = useTheme();

	const [selectedTask, setSelectedTask] = useState(null);
	const [taskModalVisible, setTaskModalVisible] = useState(false);
	const [clueModalVisible, setClueModalVisible] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [timeLeft, setTimeLeft] = useState();

	const navigateTo = (data) => {
		if (data.gameState == 'task') {
			if (data.taskType == 'question') {
				navigation.navigate('GameNav', { screen: 'QuestionScreen' });
			} else if (data.taskType == 'photo') {
				navigation.navigate('GameNav', { screen: 'PhotoScreen' });
			} else if (data.taskType == 'select') {
				navigation.navigate('GameNav', { screen: 'SelectScreen' });
			}
		}
	};


	const requestData = async (code) => {
		const result = await axios.get(`${config.apiLocation}/app/${code}`);
		setApp({ ...app, task: result.data });
		return result.data;
	};

	useEffect(() => {
		if (taskModalVisible) {
			let title = selectedTask.taskTitle;
			let text = selectedTask.requirements ? `To open this task, you must first:\n\n${selectedTask.requirements}` : 'Do you want to open this task?';
			let buttons = [{ text: 'Cancel' }];
			if (!selectedTask.requirements) buttons.push({ text: 'Open' });
			socket.send('modal', { title, text, buttons });
		} else socket.send('modal', { text: null });
	}, [taskModalVisible]);

	useEffect(() => {
		const gamecode = props?.route?.params?.gamecode;

		if (gamecode) requestData(gamecode);
		else {
			navigation.navigate('TabNav', { screen: 'HomeScreen' });
		}
	}, []);

	useEffect(() => {
		if (app?.task) navigateTo(app.task);
	}, [app]);

	useEffect(() => {
		if (!app?.task?.event_end) return false;
		const interval = setInterval(() => {
			let seconds = Math.max(app.task.event_end - Math.floor(Date.now() / 1000), 0);
			let string = `${('0' + Math.floor(seconds / 60)).substr(-2)}:${('0' + (seconds % 60)).substr(-2)}`;
			setTimeLeft(string);
		}, 1000);
		return () => clearInterval(interval);
	}, [app?.task?.event_end]);

	const pressGame = async () => {
		setTaskModalVisible(false);
		setSpinner(true);
		await axios.post(`${config.apiLocation}/app/${gamecode}/action`, {
			action: 'task',
			taskId: selectedTask?.taskId
		});
		await requestData(gamecode);

		setSpinner(false);
	};

	const calcLocation = (x, y) => {
		return [x * (width ?? 0.5), y * (height ?? 0.5)];
	};

	if (!app?.task) return
	<ActivityIndicator size="large" />


	if (app.task.gameState !== 'room') {
		console.log('Why is ROOM trying to render when not in room state?');
		return <Text>Whyyyyyy?</Text>;
	}

	return (
		<View style={styles.container}>
			<StatusBar barStyle={theme.dark ? 'light-content' : 'dark-content'} />

			<Spinner visible={!app.task || spinner} />
			{app.task && (
				<>
					<View style={styles.totalView}>
						<ImageBackground source={{ uri: app?.task?.roomBackground }} style={styles.image}>
							<View style={styles.totalView}>
								{/* Task buttons */}
								{app.task.tasks.map((task, index) => {
									if (!task.active) return null;
									const [x, y] = calcLocation(task.x, task.y);
									const [w, h] = calcLocation(task.width, task.height);
									return (
										<View
											key={index}
											style={{
												...styles.thing,
												top: y,
												left: x,
												position: 'absolute',
												filter: task.active ? 'grayscale(0)' : 'grayscale(1)'
											}}
										>
											<TouchableOpacity
												onPress={() => {
													if (task.active) {
														setSelectedTask(task);
														setTaskModalVisible(true);
													}
												}}
											>
												{task?.task_icon ? (
													<View>
														<Image
															source={{ uri: task?.task_icon }}
															style={{
																width: w,
																height: h,
																resizeMode: 'contain'
															}}
														/>
													</View>
												) : (
													<View style={task.active ? styles.thingBackground : styles.thingDisabledBackground}>
														<Text
															style={{
																whiteSpace: 'nowrap',
																overflow: 'hidden',
																textOverflow: 'ellipsis'
															}}
														>
															{task.taskType === 'question' && <FontAwesomeIcon icon={faQuestionCircle} />}
															{task.taskType === 'photo' && <FontAwesomeIcon icon={faCamera} />}
															{task.taskType === 'select' && <FontAwesomeIcon icon={faList} />}
															&nbsp;
															{task.taskTitle}
															&nbsp;
														</Text>
													</View>
												)}
											</TouchableOpacity>
										</View>
									);
								})}

								{/* Clue button */}
								{/* <View
									style={{
										...styles.thing,
										left: clueX,
										top: clueY,
										position: 'absolute'
									}}
								>
									<TouchableOpacity onPress={()=>setClueModalVisible(true)}>
										<View style={styles.thingBackground}>
											<Text style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
												Clues
												{app.task.unread_clue && (
													<FontAwesomeIcon color="red" size={20} icon={faExclamationCircle} style={{ verticalAlign: 'top' }} />
												)}
											</Text>
										</View>
									</TouchableOpacity>
								</View> */}
								{/* Instructions button */}

								<InstructionsModal calcLocation={calcLocation} app={app} gamecode={gamecode} />
								<WelcomeModal calcLocation={calcLocation} app={app} gamecode={gamecode} />
								<MapModal calcLocation={calcLocation} app={app} gamecode={gamecode} />

								<ClueModal calcLocation={calcLocation} app={app} gamecode={gamecode} requestData={requestData} />

								<TaskModal selectedTask={selectedTask} open={taskModalVisible} close={()=>{setTaskModalVisible(false)}} onClicked={pressGame} />
							</View>
						</ImageBackground>
					</View>



					<View
						style={{
							position: 'absolute',
							bottom: 0,
							backgroundColor: 'rgb(238, 238, 238)',
							borderRadius: '5px',
							padding: '5px',
							fontSize: '1.1em',
							border: '1px solid black'
						}}
					>
						<Text>{timeLeft}</Text>
					</View>
				</>
			)}
		</View>
	);
};

export default MainScreen;

const styles = StyleSheet.create({
	thing: {
		position: 'absolute',
		zIndex: 5
	},

	title: {
		fontWeight: "bold",
		fontSize: 20,
		padding: 15,
		color: "#000"
	},
	divider: {
		width: "100%",
		height: 1,
		backgroundColor: "lightgray"
	},
	thingDisabledBackground: {
		backgroundColor: 'grey',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center'
	},
	thingBackground: {
		backgroundColor: 'yellow',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		alignItems: 'center',
		justifyContent: 'center'
	},
	image: {
		// position: 'absolute',
		// left: 0,
		// right: 0,
		// top: 0,
		// bottom: 0,
		flex: 1,
		resizeMode: 'contain'
	},
	totalView: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	},
	container: {
		maxWidth: '100%',
		height: '100%',
		flex: 1,
		flexDirection: 'column'
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		// marginTop: 22
	},
	modalView: {
		margin: 20,
		// backgroundColor: 'white',
		backgroundColor: "#f9fafb",
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		minWidth: '80%'
	},
	button: {
		margin: '12px',
		borderRadius: 5,
		padding: 10,
		elevation: 2
	},
	buttonOpen: {
		backgroundColor: '#2196F3'
	},
	buttonClose: {
		backgroundColor: '#F194FF'
	},
	textStyle: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center'
	}
});
