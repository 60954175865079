import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet, Button, TextInput, Alert } from 'react-native';

import axios from 'react-native-axios';
var config = require('./../config');

import { useApp } from '../components/appContext';

import Spinner from 'react-native-loading-spinner-overlay';

function TypeScreen(props) {
	const [spinner, setSpinner] = useState(false);
	const [spinnerStatus, setSpinnerStatus] = useState('');
	const [submitStatus, setSubmitStatus] = useState('');
	const [gameCode, setGameCode] = React.useState('');

	const [gamecode, setGamecode] = React.useState('1');

	const { user, setUser, app, setApp } = useApp();
	const token = user?.token;

	const openGame = () => {
		setSpinner(true);

		setSpinnerStatus('Connecting...');
		setTimeout(async () => {
			try {
				const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
				setApp({ ...app, gamecode: gamecode, task: result.data });
			} catch (error) {
				console.error(error);

				Alert.alert('Sorry, there was an error.', error.message);
			}

			setSpinner(false);
			props.navigation.navigate('GameNav', { screen: 'MainScreen', params: { gamecode: gamecode } });
		}, 500);
	};

	return (
		<View style={styles.container}>
			<Spinner visible={spinner} textContent={spinnerStatus} textStyle={styles.spinnerTextStyle} />
			<View style={styles.barcodeInputContainer}>
				<TextInput style={styles.input} onChangeText={setGamecode} value={gamecode} placeholder={'Fill in...'} />

				<Button title="Enter" onPress={() => openGame()} />
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	spinnerTextStyle: {
		color: '#FFF'
	},
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		marginTop: '5%'
		// justifyContent: 'center',
	},
	maintext: {
		fontSize: 16,
		margin: 20
	},
	addButton: {
		color: 'green',
		marginLeft: 16
	},
	removeButton: {
		color: 'red',
		alignSelf: 'flex-end',
		marginRight: 16
	},
	item: {
		marginVertical: 8,
		marginHorizontal: 16,
		backgroundColor: '#FDD7E4',
		width: '100%',
		alignSelf: 'center'
	},
	barcodebox: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 300,
		width: 300,
		overflow: 'hidden',
		borderRadius: 30,
		backgroundColor: 'tomato'
	},

	barcodeInputContainer: {
		// flex: 1,
		flexDirection: 'row',
		// alignItems: 'center',
		justifyContent: 'flex-start'
	},

	buttonsContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttonContainer: {
		flex: 1
	}
});

export default TypeScreen;
