import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, Image, TouchableOpacity, Modal, Pressable } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import axios from 'react-native-axios';
var config = require('../../../config');
import socket from '../../../components/socket';

const InstructionsModal = ({ calcLocation, app, gamecode }) => {
	const [instructionsX, instructionsY] = calcLocation(app.task.help_icon_x, app.task.help_icon_y);
	const [introModalVisible, setIntroModalVisible] = useState(false || app.task.show_instructions);
	const [introClosing, setIntroClosing] = useState(false);
	const [introTab, setIntroTab] = useState(0);
	const nextIntroTab = () => {
		setIntroTab(introTab + 1);
	};
	const prevIntroTab = () => {
		setIntroTab(introTab - 1);
	};
	const completeIntro = async () => {
		try {
			setIntroClosing(true);
			await axios.post(`${config.apiLocation}/app/${gamecode}/action`, {
				action: 'instructions-closed'
			});
			socket.send('modal', { text: null });
			setIntroClosing(false);
			setIntroModalVisible(false);
		} catch (error) {}
	};

	useEffect(() => {
		if (!introModalVisible) return;
		const instr = app.task.instructions[introTab];
		socket.send('modal', { text: instr.description, title: instr.title, image: instr.img_url });
	}, [introModalVisible, introTab]);

	return (
		<View
			style={{
				...styles.thing,
				left: instructionsX,
				top: instructionsY,
				position: 'absolute'
			}}
		>
			<TouchableOpacity
				style={{ padding: '5px', fontWeight: 'bold' }}
				onPress={() => {
					setIntroTab(0);
					setIntroModalVisible(true);
				}}
			>
				<View style={[styles.thingBackground]}>
					<Text style={{ fontWeight: 'bold' }}>Help</Text>
				</View>
			</TouchableOpacity>
			<Modal animationType="slide" transparent={true} visible={introModalVisible}>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<Text
							style={{
								...styles.modalText,
								borderBottomColor: '#000000',
								borderBottomWidth: 1
							}}
						>
							Instructions - {app.task.instructions[introTab].title}
						</Text>
						<Text style={styles.modalText}>{app.task.instructions[introTab].description}</Text>
						<Image source={{ uri: app.task.instructions[introTab].img_url }} style={{ width: 200, height: 200, resizeMode: 'contain' }} />

						<View
							style={{
								display: 'inline-flex',
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}
						>
							{introTab > 0 && (
								<Pressable style={[styles.button, styles.buttonOpen, { alignSelf: 'flex-start' }]} onPress={prevIntroTab}>
									<Text style={styles.textStyle}>Back</Text>
								</Pressable>
							)}
							{introTab !== app.task.instructions.length - 1 && (
								<Pressable style={[styles.button, styles.buttonOpen, { alignSelf: 'flex-end' }]} onPress={nextIntroTab}>
									<Text style={styles.textStyle}>Next</Text>
								</Pressable>
							)}
							{introTab === app.task.instructions.length - 1 && (
								<Pressable style={[styles.button, styles.buttonClose, { alignSelf: 'flex-end' }]} onPress={completeIntro}>
									{introClosing ? <FontAwesomeIcon color="white" spin="true" icon={faSync} /> : <Text style={styles.textStyle}>Close</Text>}
								</Pressable>
							)}
						</View>
					</View>
				</View>
			</Modal>
		</View>
	);
};

export default InstructionsModal;

const styles = StyleSheet.create({
	thing: {
		position: 'absolute',
		zIndex: 5
	},
	thingDisabledBackground: {
		backgroundColor: 'grey',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center'
	},
	thingBackground: {
		backgroundColor: 'yellow',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		alignItems: 'center',
		justifyContent: 'center'
	},
	totalView: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
	},
	button: {
		margin: '12px',
		borderRadius: 5,
		padding: 10,
		elevation: 2
	},
	buttonOpen: {
		backgroundColor: '#2196F3'
	},
	buttonClose: {
		backgroundColor: '#F194FF'
	},
	textStyle: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center'
	}
});
