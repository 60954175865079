import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, Image, TouchableOpacity, Modal, Pressable } from 'react-native';
import socket from '../../../components/socket';

const MapModal = ({ calcLocation, app, gamecode }) => {

	console.log(app)
	const [iconX, iconY] = calcLocation(app.task.map_icon_x, app.task.map_icon_y);
	const [visible, setVisible] = useState(false);

	const completeIntro = async () => {
		try {
			socket.send('modal', { text: null });
			setVisible(false);
		} catch (error) { }
	};

	useEffect(() => {
		if (!visible) return;
		socket.send('modal', { text: app.task.map_description, title: app.task.map_title, image: app.task.map_url });

		console.log(app)
	}, [visible]);

	if (!app.task.show_map_icon) return false;
	return (
		<View
			style={{
				...styles.thing,
				left: iconX,
				top: iconY,
				position: 'absolute'
			}}
		>
			<TouchableOpacity style={{ padding: '5px', fontWeight: 'bold' }} onPress={() => setVisible(true)}>
				<View style={[styles.thingBackground]}>
					<Text style={{ fontWeight: 'bold' }}>
							{app.task.map_label}
							</Text>
				</View>
			</TouchableOpacity>
			<Modal animationType="slide" transparent={true} visible={visible}>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<Text
							style={{
								...styles.modalText,
								borderBottomColor: '#000000',
								borderBottomWidth: 1
							}}
						>
							{app.task.map_title}
						</Text>
						<Text style={styles.modalText}>
							{app.task.map_description}
						</Text>
						<Image source={{ uri: app.task.map_url }} style={{ width: 200, height: 200, resizeMode: 'contain' }} />

						<View
							style={{
								display: 'inline-flex',
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}
						>
							<Pressable style={[styles.button, styles.buttonClose, { alignSelf: 'flex-end' }]} onPress={completeIntro}>
								<Text style={styles.textStyle}>Close</Text>
							</Pressable>
						</View>
					</View>
				</View>
			</Modal>
		</View>
	);
};

export default MapModal;

const styles = StyleSheet.create({
	thing: {
		position: 'absolute',
		zIndex: 5
	},
	thingDisabledBackground: {
		backgroundColor: 'grey',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center'
	},
	thingBackground: {
		backgroundColor: 'yellow',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		alignItems: 'center',
		justifyContent: 'center'
	},
	totalView: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
	},
	button: {
		margin: '12px',
		borderRadius: 5,
		padding: 10,
		elevation: 2
	},
	buttonOpen: {
		backgroundColor: '#2196F3'
	},
	buttonClose: {
		backgroundColor: '#F194FF'
	},
	textStyle: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center'
	}
});
