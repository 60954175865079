import React, { useEffect, useState } from 'react';
import { StatusBar } from 'react-native';

import { NavigationContainer, DefaultTheme as NavigationDefaultTheme, DarkTheme as NavigationDarkTheme } from '@react-navigation/native';

import { Provider as PaperProvider, DefaultTheme as PaperDefaultTheme, DarkTheme as PaperDarkTheme } from 'react-native-paper';

import ScannerScreen from './screens/ScannerScreen';
import TypeScreen from './screens/TypeScreen';

import { AppProvider } from './components/appContext';

import { createStackNavigator } from '@react-navigation/stack';

import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';

import Icon from 'react-native-vector-icons/Ionicons';

import HomeScreen from './screens/HomeScreen';

import MainScreen from './screens/Game/MainScreen';

import SelectScreen from './screens/Game/SelectScreen';
import QuestionScreen from './screens/Game/QuestionScreen';
import PhotoScreen from './screens/Game/PhotoScreen';

import DetailsScreen from './screens/DetailsScreen';
import ProfileScreen from './screens/ProfileScreen';
import socket from './components/socket';

const AppStack = createStackNavigator();
const GameStack = createStackNavigator();
const HomeStack = createStackNavigator();
const DetailsStack = createStackNavigator();

const Tab = createMaterialBottomTabNavigator();

const App = () => {
	const [isDarkTheme, setIsDarkTheme] = useState(false);

	const CustomDefaultTheme = {
		...NavigationDefaultTheme,
		...PaperDefaultTheme,
		colors: {
			...NavigationDefaultTheme.colors,
			...PaperDefaultTheme.colors,
			background: '#ffffff',
			text: '#333333'
		}
	};

	const CustomDarkTheme = {
		...NavigationDarkTheme,
		...PaperDarkTheme,
		colors: {
			...NavigationDarkTheme.colors,
			...PaperDarkTheme.colors,
			background: '#333333',
			text: '#ffffff'
		}
	};

	useEffect(() => {
		let interval = setInterval(() => socket.send('heartbeat'), 5000);
		return () => clearInterval(interval);
	}, []);

	const theme = isDarkTheme ? CustomDarkTheme : CustomDefaultTheme;

	function GameNav() {
		return (
			<GameStack.Navigator
				screenOptions={{
					headerStyle: {
						backgroundColor: '#009387'
					},
					headerTintColor: '#fff',
					headerTitleStyle: {
						fontWeight: 'bold'
					}
				}}
			>
				<HomeStack.Screen
					name="MainScreen"
					component={MainScreen}
					options={{
						headerShown: false
					}}
				/>
				<HomeStack.Screen
					name="QuestionScreen"
					component={QuestionScreen}
					options={{
						headerShown: false
					}}
				/>
				<HomeStack.Screen
					name="SelectScreen"
					component={SelectScreen}
					options={{
						headerShown: false
					}}
				/>

				<HomeStack.Screen
					name="PhotoScreen"
					component={PhotoScreen}
					options={{
						headerShown: false
					}}
				/>
			</GameStack.Navigator>
		);
	}

	function TabNav() {
		return (
			<Tab.Navigator initialRouteName="Home" activeColor="#fff">
				<Tab.Screen
					name="..."
					component={HomeStackScreen}
					options={{
						tabBarLabel: '...',
						tabBarColor: '#009387',
						tabBarIcon: ({ color }) => <Icon name="ios-home" color={color} size={26} />
					}}
				/>
			</Tab.Navigator>
		);
	}

	return (
		<PaperProvider theme={theme}>
			<StatusBar style="light" />
			{/* <AuthContext.Provider value={authContext}> */}
			<NavigationContainer theme={theme}>
				<AppStack.Navigator>
					<AppStack.Screen name="TabNav" component={TabNav} options={{ headerShown: false }} />

					<AppStack.Screen name="GameNav" component={GameNav} options={{ headerShown: false }} />
				</AppStack.Navigator>
			</NavigationContainer>
		</PaperProvider>
	);
};

const HomeStackScreen = ({ navigation }) => (
	<HomeStack.Navigator
		screenOptions={{
			headerStyle: {
				backgroundColor: '#009387'
			},
			headerTintColor: '#fff',
			headerTitleStyle: {
				fontWeight: 'bold'
			}
		}}
	>
		<HomeStack.Screen
			name="ActivityTrack"
			component={HomeScreen}
			options={{
				title: 'Welcome'
				// headerLeft: () => (
				//   <Icon.Button name="ios-menu" size={25} backgroundColor="#009387" onPress={() => navigation.openDrawer()}></Icon.Button>
				// )
			}}
		/>

		<HomeStack.Screen
			name="ScannerScreen"
			component={ScannerScreen}
			options={{
				title: 'Scan game code'
			}}
		/>
		<HomeStack.Screen
			name="TypeScreen"
			component={TypeScreen}
			options={{
				title: 'Type game code'
			}}
		/>
	</HomeStack.Navigator>
);

const DetailsStackScreen = ({ navigation }) => (
	<DetailsStack.Navigator
		screenOptions={{
			headerStyle: {
				backgroundColor: '#1f65ff'
			},
			headerTintColor: '#fff',
			headerTitleStyle: {
				fontWeight: 'bold'
			}
		}}
	>
		<DetailsStack.Screen
			name="Details"
			component={DetailsScreen}
			options={{
				title: 'ActivityTrack'
				// headerLeft: () => (
				//   <Icon.Button name="ios-menu" size={25} backgroundColor="#1f65ff" onPress={() => navigation.openDrawer()}></Icon.Button>
				// )
			}}
		/>
	</DetailsStack.Navigator>
);

const FinishedStackScreen = ({ navigation }) => (
	<DetailsStack.Navigator
		screenOptions={{
			headerStyle: {
				backgroundColor: '#1f65ff'
			},
			headerTintColor: '#fff',
			headerTitleStyle: {
				fontWeight: 'bold'
			}
		}}
	>
		<DetailsStack.Screen
			name="Profile"
			component={ProfileScreen}
			options={{
				title: 'ActivityTrack'
				// headerLeft: () => (
				//   <Icon.Button name="ios-menu" size={25} backgroundColor="#1f65ff" onPress={() => navigation.openDrawer()}></Icon.Button>
				// )
			}}
		/>
	</DetailsStack.Navigator>
);

export default () => (
	<AppProvider>
		<App />
	</AppProvider>
);
