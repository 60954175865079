import React from 'react';
import { View, StyleSheet, Text, Modal, Pressable, } from 'react-native';

const TaskModal = ({ selectedTask, open, close, onClicked }) => {

	{/* Task modal */ }
	return (

		<Modal animationType="slide" transparent={true} visible={open} onRequestClose={() => close()}>
			{selectedTask && (
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<Text style={styles.modalText}>{selectedTask.taskTitle}</Text>
						<Text style={styles.modalText}>
							{selectedTask.reward_room ? '' : <>{selectedTask.taskType == 'photo' ? 'Photo challenge' : 'Question'}</>}
						</Text>
						{selectedTask.requirements ?
							<Text style={{ color: 'red' }}>
								<b>To open this task, you must first:</b>
								<p>{selectedTask.requirements}</p>
							</Text>
							:
							null
						}
						<View
							style={{
								display: 'inline-flex',
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}
						>
							<Pressable style={[styles.button, styles.buttonClose]} onPress={() => close()}>
								<Text style={styles.textStyle}>Cancel</Text>
							</Pressable>
							{
								!selectedTask.requirements ?
									<Pressable style={[styles.button, styles.buttonOpen]} onPress={() => onClicked()}>
										<Text style={styles.textStyle}>Open</Text>
									</Pressable>
									:
									null
							}
						</View>
					</View>
				</View>
			)}
		</Modal>
	);
}


export default TaskModal;

const styles = StyleSheet.create({
	thing: {
		position: 'absolute',
		zIndex: 5
	},
	thingDisabledBackground: {
		backgroundColor: 'grey',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center'
	},
	thingBackground: {
		backgroundColor: 'yellow',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		alignItems: 'center',
		justifyContent: 'center'
	},
	totalView: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
	},
	button: {
		margin: '12px',
		borderRadius: 5,
		padding: 10,
		elevation: 2
	},
	buttonOpen: {
		backgroundColor: '#2196F3'
	},
	buttonClose: {
		backgroundColor: '#F194FF'
	},
	textStyle: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center'
	}
});
