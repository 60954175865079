import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, Modal, Button, TouchableOpacity, ActivityIndicator } from 'react-native';
import socket from '../../../components/socket';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';


import axios from 'react-native-axios';
var config = require('../../../config');

const ClueModal = ({ calcLocation, app, gamecode, requestData }) => {
	const [clueX, clueY] = calcLocation(app.task.clue_icon_x, app.task.clue_icon_y);
	const [clueModalVisible, setClueModalVisible] = useState(false);

	const [clueShopModalVisible, setClueShopModalVisible] = useState(false);

	const [loading, setLoading] = useState(false);

	const [disableBuyButton, setDisableBuyButton] = useState(false);

	const closeClues = async () => {
		setClueModalVisible(false);
		socket.send('modal', { text: null });
	};
	const showClues = async () => {

		setClueModalVisible(true);
		setLoading(true)
		await requestData(gamecode)
		setLoading(false)

		await axios.post(`${config.apiLocation}/app/${gamecode}/action`, {
			action: 'clues-read'
		});
		socket.send('modal', {
			text: app?.task?.clues.length > 0 ?
				app?.task?.clues.toString().replaceAll(',', '\r\n') :
				`No clues unlocked\n
			 Try completing a task first`,
			Ftitle: 'Clues', image: null
		});
	};


	return (
		<View
			style={{
				...styles.thing,
				left: clueX,
				top: clueY,
				position: 'absolute'
			}}
		>

			<TouchableOpacity onPress={showClues}>
				<View style={styles.thingBackground}>
					<Text style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
						Clues
						{app.task.unread_clue && (
							<FontAwesomeIcon color="red" size={20} icon={faExclamationCircle} style={{ verticalAlign: 'top' }} />
						)}
					</Text>
				</View>
			</TouchableOpacity>

			{/* Clues modal */}
			<Modal animationType="slide" transparent={true} visible={clueModalVisible}>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>

						<View>
							<Text style={styles.title}>Clues</Text>
							<View style={styles.divider}></View>
						</View>
						{
							loading ?
								<View
									style={{
										marginTop: "2rem",
										marginBottom: "2rem"
									}}>
									<ActivityIndicator size="large" />
								</View>
								:
								<CluesModalContent clues={app?.task?.clues} />
						}

						<View
							style={{
								display: 'inline-flex',
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}
						>
							<View style={{ margin: '12px' }}>
								<Button
									title={`Close`}
									color="#F194FF"
									onPress={closeClues}>
								</Button>
							</View>
							<View style={{ margin: '12px' }}>
								<Button
									title={`Buy`}
									disabled={app.task.score < app.task.clue_price}
									style={{ margin: '12px' }}
									// style={styles.textStyle}
									onPress={async () => { setClueShopModalVisible(true) }}>
								</Button>
							</View>
						</View>
					</View>
				</View>
			</Modal>

			{/* Clue shop modal */}
			<Modal animationType="slide" transparent={true} visible={clueShopModalVisible}>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>

						<View>
							<Text style={styles.title}>Clue shop</Text>
							<View style={styles.divider}></View>
						</View>

						<View
							style={{
								marginTop: "2rem",
								marginBottom: "2rem",
								textAlign: "center"
							}}>
							<Text>This will cost you {app.task.clue_price} points.</Text>
							<Text>Each clue gets more expensive.</Text>
							<Text style={{ marginTop: "1rem" }}>Are you sure?</Text>
						</View>



						<View
							style={{
								display: 'inline-flex',
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}
						>
							<View style={{ margin: '12px' }}>
								<Button
									title={`Close`}
									style={{ alignSelf: 'flex-start' }}
									color="#F194FF"
									onPress={() => setClueShopModalVisible(false)}>
								</Button>
							</View>
							<View style={{ margin: '12px' }}>
								<Button
									title={`Purchase`}
									style={{ alignSelf: 'flex-start' }}
									disabled={(app.task.score < app.task.clue_price) || disableBuyButton}
									// style={styles.textStyle}
									onPress={async () => {
										setDisableBuyButton(true)
										await axios.post(`${config.apiLocation}/app/${gamecode}/action`, {
											action: 'purchase-clue',
										});
										await requestData(gamecode);
										setDisableBuyButton(false)
										setClueShopModalVisible(false)
									}}>
								</Button>
							</View>
						</View>
					</View>
				</View>
			</Modal>
		</View>
	);
};

const CluesModalContent = ({ clues }) => {

	return (
		<>
			{
				clues.length > 0 ?
					clues?.map((clue, index) => {
						return (
							<Text key={index} style={styles.modalText}>
								{clue}
							</Text>
						);
					})
					:
					<View
						style={{
							marginTop: "2rem",
							marginBottom: "2rem",
							textAlign: "center"
						}}>
						<Text>
							No clues unlocked
						</Text>
						<Text>
							Try completing a task first
						</Text>
					</View>
			}
		</>
	)
}

export default ClueModal;

const styles = StyleSheet.create({
	thing: {
		position: 'absolute',
		zIndex: 5
	},
	thingDisabledBackground: {
		backgroundColor: 'grey',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center'
	},
	thingBackground: {
		backgroundColor: 'yellow',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		alignItems: 'center',
		justifyContent: 'center'
	},
	totalView: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
	},
	button: {
		margin: '12px',
		borderRadius: 5,
		padding: 10,
		elevation: 2
	},
	buttonOpen: {
		backgroundColor: '#2196F3'
	},
	buttonClose: {
		backgroundColor: '#F194FF'
	},
	textStyle: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center'
	}
});
