import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, StatusBar, TextInput, Text, Button, Image, Modal, Pressable, ImageBackground } from 'react-native';
import { useTheme } from '@react-navigation/native';

var config = require('../../config');
import axios from 'react-native-axios';

import Spinner from 'react-native-loading-spinner-overlay';
import { useApp } from '../../components/appContext';
import socket from '../../components/socket';

import { Audio } from 'expo-av';

const SelectScreen = ({ navigation, ...props }) => {
	const { colors } = useTheme();

	const theme = useTheme();

	const [question, setQuestion] = useState(null);
	const [text, onChangeText] = React.useState('');

	const [loading, setLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [answerVersion, setAnswerVersion] = useState(1);

	const [selectedAnswer, setSelectedAnswer] = useState(null);

	const [error, setError] = useState(false);

	const [submitResult, setSubmitResult] = useState(null);
	const { app, setApp } = useApp();
	const gamecode = app.gamecode;

	const [sound, setSound] = React.useState();

	async function playSound(sound_file) {
		console.log('Loading Sound');
		const { sound } = await Audio.Sound.createAsync(`http://www.surveysays.online/assets/${sound_file}`);
		setSound(sound);

		console.log('Playing Sound');
		await sound.playAsync();
	}

	useEffect(() => {
		if (!selectedAnswer) return;
		socket.send('answer_selected', {
			game_id: question.game_id,
			selected_answer_id: selectedAnswer,
			selected_answer_version: answerVersion
		});
		setAnswerVersion(answerVersion + 1);
	}, [selectedAnswer]);

	// React.useEffect(() => {
	//   return sound
	//     ? () => {
	//         console.log('Unloading Sound');
	//         sound.unloadAsync(); }
	//     : undefined;
	// }, [sound]);

	const requestQuestion = () => {
		(async () => {
			const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
			setQuestion(result.data);
			setApp({ ...app, task: result.data });
			setLoading(false);
		})();
	};

	const submitAnswer = () => {
		(async () => {
			setIsSubmitting(true);

			try {
				const result = await axios.post(`${config.apiLocation}/app/${gamecode}/submit`, {
					submitType: 'task',
					taskId: app.task.taskId,
					taskAnswer: selectedAnswer
				});

				setSubmitResult(result.data);
				if (result.data.sound) playSound(result.data.sound);
				socket.send('modal', { text: result.data?.message });
			} catch (error) {
				console.log(error);
				setError(true);
			}

			setIsSubmitting(false);
			setModalVisible(true);
		})();
	};

	useEffect(() => {
		requestQuestion();
	}, []);

	const backButton = useCallback(async () => {
		try {
			setSpinner(true);
			await axios.post(`${config.apiLocation}/app/${gamecode}/action`, {
				action: 'room',
				taskId: null
			});

			const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
			setQuestion(result.data);
			setApp({ ...app, task: result.data });

			await navigation.goBack();
			setSpinner(false);
		} catch (error) {
			console.log(error);
		}
	}, []);

	const errorModal = () => {
		return (
			<>
				<Text style={styles.modalText}>Sorry, Something went wrong. Try again</Text>
				<Pressable
					style={[styles.button, styles.buttonOpen]}
					onPress={async () => {
						setModalVisible(false);
						setError(false);
					}}
				>
					<Text style={styles.textStyle}>Ok</Text>
				</Pressable>
			</>
		);
	};

	const successModal = () => {
		if (!submitResult) return 'Loading.....';
		return (
			<>
				<Text style={styles.modalText}>{submitResult?.message}</Text>
				<Pressable
					style={[styles.button, styles.buttonOpen]}
					onPress={async () => {
						socket.send('modal', { text: null });
						setLoading(true);
						setModalVisible(false);
						const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
						setApp({ ...app, task: result.data });

						await navigation.goBack();
						setLoading(false);
					}}
				>
					<Text style={styles.textStyle}>Ok</Text>
				</Pressable>
			</>
		);
	};

	if (!question) return <Spinner />;
	if (question.gameState !== 'task') {
		console.log('Why is the select screen showing when not on a task????');
		return 'WHYYY';
	}

	return (
		<ImageBackground source={{ uri: app?.task?.roomBackground }} style={{ flex: 1, resizeMode: 'cover' }}>
			<View style={styles.container}>
				<Spinner visible={isSubmitting || loading || spinner} />

				<View style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
					<Button title="Back" onPress={backButton} />
					<Text
						style={{
							fontWeight: 'bold',
							fontSize: '1.2em',
							background: 'rgba(255,255,255,0.9)',
							borderRadius: '25px',
							padding: '5px',
							paddingRight: '12px',
							paddingLeft: '12px'
						}}
					>
						{question.taskTitle}
					</Text>
					<Text>&nbsp;</Text>
				</View>

				{question.taskImage && <Image style={styles.image} source={{ uri: question.taskImage }} />}

				<View style={{ textAlign: 'center', background: 'rgba(255,255,255,0.9)', borderRadius: '25px', padding: '10px', marginBottom: '20px' }}>
					<Text style={{ marginBottom: '20px' }}>{question.taskDescription}</Text>
					{question.answers.map((answer, index) => {
						return (
							<Pressable
								key={index}
								style={[styles.button, selectedAnswer === answer.answer_id ? styles.buttonOpen : styles.buttonClose, { marginBottom: '3px' }]}
								onPress={async () => {
									setSelectedAnswer(answer.answer_id);
								}}
							>
								<Text style={{ color: 'white' }}>{answer.answer_value}</Text>
							</Pressable>
						);
					})}
				</View>

				<Button disabled={!selectedAnswer} title="Submit" onPress={() => submitAnswer()} />
				<Modal
					animationType="slide"
					transparent={true}
					visible={modalVisible}
					onRequestClose={() => {
						setModalVisible(!modalVisible);
					}}
				>
					<View style={styles.centeredView}>
						<View style={styles.modalView}>{error ? errorModal() : successModal()}</View>
					</View>
				</Modal>
			</View>
		</ImageBackground>
	);
};

export default SelectScreen;

const styles = StyleSheet.create({
	image: {
		flex: 1,
		resizeMode: 'contain',
		marginTop: '16px',
		marginBottom: '16px'
	},
	container: { padding: '20px', justifyContent: 'space-between', flexDirection: 'column', flex: 1 },
	spinnerTextStyle: {
		color: '#FFF'
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
	},
	button: {
		borderRadius: 20,
		padding: '5px',
		elevation: 2,
		textAlign: 'center'
	},
	buttonOpen: {
		backgroundColor: '#F194FF',
		color: 'white'
	},
	buttonClose: {
		backgroundColor: '#2196F3',
		color: 'white'
	},
	textStyle: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center'
	}
});
