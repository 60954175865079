import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, Modal, Pressable } from 'react-native';
import socket from '../../../components/socket';

import axios from 'react-native-axios';
var config = require('../../../config');

const WelcomeModal = ({ calcLocation, app, gamecode }) => {
	const [buttonX, buttonY] = calcLocation(0.35, 0.95);
	const [introModalVisible, setIntroModalVisible] = useState(false || app.task.show_welcome);

	const dismissWelcome = async () => {
		try {
			await axios.post(`${config.apiLocation}/app/${gamecode}/action`, {
				action: 'welcome-closed'
			});
			socket.send('modal', { text: null });
			setIntroModalVisible(false);
		} catch (error) {}
	};

	useEffect(() => {
		if (!introModalVisible) return;
		socket.send('modal', { text: app.task.welcome, title: 'Welcome' });
	}, [introModalVisible]);

	return (
		<View
			style={{
				...styles.thing,
				left: buttonX,
				top: buttonY,
				position: 'absolute'
			}}
		>
			<Modal animationType="slide" transparent={true} visible={introModalVisible}>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<Text
							style={{
								...styles.modalText,
								borderBottomColor: '#000000',
								borderBottomWidth: 1
							}}
						>
							Welcome
						</Text>
						<Text style={styles.modalText}>{app.task.welcome}</Text>
						{/* <Image source={{ uri: app.task.instructions[introTab].img_url }} style={{ width: 200, height: 200 }} /> */}

						<View
							style={{
								display: 'inline-flex',
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}
						>
							{app.task.tasks.length > 0 && (
								<Pressable style={[styles.button, styles.buttonClose, { alignSelf: 'flex-end' }]} onPress={dismissWelcome}>
									<Text style={styles.textStyle}>Close</Text>
								</Pressable>
							)}
						</View>
					</View>
				</View>
			</Modal>
		</View>
	);
};

export default WelcomeModal;

const styles = StyleSheet.create({
	thing: {
		position: 'absolute',
		zIndex: 5
	},
	thingDisabledBackground: {
		backgroundColor: 'grey',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center'
	},
	thingBackground: {
		backgroundColor: 'yellow',
		// border: '1px solid black',
		borderRadius: 100,
		padding: '5px',
		width: 'auto',
		height: 'auto',
		alignItems: 'center',
		justifyContent: 'center'
	},
	totalView: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
	},
	button: {
		margin: '12px',
		borderRadius: 5,
		padding: 10,
		elevation: 2
	},
	buttonOpen: {
		backgroundColor: '#2196F3'
	},
	buttonClose: {
		backgroundColor: '#F194FF'
	},
	textStyle: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center'
	}
});
