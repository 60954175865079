import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, StatusBar, Text, Button, Image, Platform, Modal, Pressable, ImageBackground } from 'react-native';
import { useTheme } from '@react-navigation/native';

import axios from 'react-native-axios';
var config = require('./../../config');

import Spinner from 'react-native-loading-spinner-overlay';
import * as ImagePicker from 'expo-image-picker';
import * as FileSystem from 'expo-file-system';
import { Buffer } from 'buffer';
import { useApp } from '../../components/appContext';
import socket from '../../components/socket';

import { Audio } from 'expo-av';

const PhotoScreen = ({ navigation }) => {
	const { colors } = useTheme();

	const theme = useTheme();

	const { setApp, app } = useApp();
	const gamecode = app.gamecode;

	const [question, setQuestion] = useState(null);
	const [image, setImage] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [submitResult, setSubmitResult] = useState(null);

	const [spinner, setSpinner] = useState(false);

	const [error, setError] = useState(false);

	const [sound, setSound] = React.useState();

	async function playSound() {
		console.log('Loading Sound');
		const { sound } = await Audio.Sound.createAsync('http://www.surveysays.online/assets/payout.mp3');
		setSound(sound);

		console.log('Playing Sound');
		await sound.playAsync();
	}

	const requestQuestion = () => {
		(async () => {
			const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
			setQuestion(result.data);
			setApp({ ...app, task: result.data });
		})();
	};

	const fetchImageFromUri = async (uri) => {
		const response = await fetch(uri);
		const blob = await response.blob();
		return blob;
	};

	const submitImage = async () => {
		setUploading(true);

		//On web this function doesn't do anything
		const img = await fetchImageFromUri(image);

		let files = [];
		files.push({ ...img, location: image });

		try {
			var signed_files = [];
			if (Platform.OS !== 'web') {
				files.forEach((f) => {
					signed_files.push({
						name: f?._data?.name,
						type: "JPEG",//f?._data?.type,
						size: f?._data?.size
					});
				});
			} else {
				files.forEach((f) => {
					signed_files.push({
						name: 'test',
						type: 'JPEG',
						size: 0
					});
				});
			}
			//Get S3 url
			var { data } = await axios.post(`${config.apiLocation}/s3/signed-url`, { files: signed_files });

			var instance = axios.create();
			delete instance.defaults.headers.common['Authorization'];
			for (let i = 0; i < data.length; i++) {
				try {
					const { name, size, type, url, file_id } = data[i];
					const { location } = files[i];

					// console.log(JSON.stringify(location))

					const cleanLocation = location.replace('file://', '');

					if (Platform.OS !== 'web') {
						const base64 = await FileSystem.readAsStringAsync(image, {
							encoding: FileSystem.EncodingType.Base64
						});
						const buffer = Buffer.from(base64, 'base64');

						//upload file
						await instance.put(url, buffer, {
							headers: {
								// 'Content-Type': type
								'Content-Type': 'multipart/form-data'
							}
						});
					} else {
						// const result = await fetch(location)
						// console.log(result)
						// let blobler = await result.blob();
						// const base64 = await blobToBase64(blobler)

						const buffer = Buffer.from(location.replace(/^data:image\/\w+;base64,/, ''), 'base64');
						//upload file
						await instance.put(url, buffer, {
							headers: {
								// 'Content-Type': type
								'Content-Encoding': 'base64',
								'Content-Type': 'multipart/form-data'
							}
						});
					}

					//Update DB
					const submitresult = await axios.post(`${config.apiLocation}/app/${gamecode}/submit`, {
						submitType: 'task',
						taskId: app.task.taskId,
						taskAnswer: file_id
					});

					setSubmitResult(submitresult.data);
					socket.send('modal', { text: submitresult.data.message, image: submitresult.data.image });

					const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
					setApp({ ...app, task: result.data });

					// console.log(JSON.stringify(result.data))
					setUploading(false);
					// navigation.goBack();
					playSound();
					setModalVisible(true);
				} catch (error) {
					console.error(error);
					setError(true);
					setUploading(false);
				}
			}
		} catch (error) {
			console.error(error);
			setUploading(false);
		}
	};

	const backButton = useCallback(async () => {
		try {
			setSpinner(true);
			await axios.post(`${config.apiLocation}/app/${gamecode}/action`, {
				action: 'room',
				taskId: null
			});

			const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
			setQuestion(result.data);
			setApp({ ...app, task: result.data });

			await navigation.goBack();
			setSpinner(false);
		} catch (error) {
			console.log(error);
		}
	}, []);

	const pickImage = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1
		});

		if (!result.cancelled) {
			setImage(result.uri);
		}
	};

	const takeImage = async () => {
		let result = await ImagePicker.launchCameraAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1
		});

		if (!result.cancelled) {
			setImage(result.uri);
		}
	};

	useEffect(() => {
		(async () => {
			if (Platform.OS !== 'web') {
				const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
				if (status !== 'granted') {
					alert('Sorry, we need camera roll permissions to make this work!');
				}
			}
		})();

		requestQuestion();
	}, []);

	const errorModal = () => {
		return (
			<>
				<Text style={styles.modalText}>Sorry, Something went wrong. Try again</Text>
				<Pressable
					style={[styles.button, styles.buttonOpen]}
					onPress={async () => {
						setModalVisible(false);
						setError(false);
					}}
				>
					<Text style={styles.textStyle}>Ok</Text>
				</Pressable>
			</>
		);
	};

	const successModal = () => {
		if (!submitResult) return 'Loading...';
		return (
			<>
				<Text style={styles.modalText}>{submitResult?.message}</Text>
				<Pressable
					style={[styles.button, styles.buttonOpen]}
					onPress={async () => {
						socket.send('modal', { text: null });
						setSpinner(true);
						setModalVisible(false);
						const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
						setApp({ ...app, task: result.data });

						await navigation.goBack();
						setSpinner(false);
					}}
				>
					<Text style={styles.textStyle}>Ok</Text>
				</Pressable>
			</>
		);
	};

	if (!question) return <Spinner />;

	return (
		<ImageBackground source={{ uri: app?.task?.roomBackground }} style={{ flex: 1, resizeMode: 'cover' }}>
			<View style={styles.container}>
				<Spinner visible={question == null || uploading || spinner} />
				<View style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
					<Button title="Back" onPress={backButton} />
					<Text
						style={{
							fontWeight: 'bold',
							fontSize: '1.2em',
							background: 'rgba(255,255,255,0.9)',
							borderRadius: '25px',
							padding: '5px',
							paddingRight: '12px',
							paddingLeft: '12px'
						}}
					>
						{question.taskTitle}
					</Text>
					<Text>&nbsp;</Text>
				</View>

				{image ? (
					<>
						<Image source={{ uri: image }} style={styles.image} />
					</>
				) : (
					question.taskImage && (
						<Image
							style={styles.image}
							source={{
								uri: question.taskImage
							}}
						/>
					)
				)}

				<View style={styles.imageContainer}>
					<View style={{ textAlign: 'center', background: 'rgba(255,255,255,0.9)', borderRadius: '25px', padding: '10px', marginBottom: '20px' }}>
						<Text>{question.taskDescription}</Text>
					</View>
					{image ? (
						<>
							<Text>&nbsp;</Text>
							<Button
								title="Redo"
								onPress={() => {
									setImage(null);
								}}
							/>
							<Text>&nbsp;</Text>
							<Button title="Submit photo" onPress={submitImage} />
						</>
					) : Platform.OS !== 'web' ? (
						<>
							<Text>&nbsp;</Text>
							<Button title="Take a photo" onPress={takeImage} />
							<Text>&nbsp;</Text>
							<Button title="Choose a photo" onPress={pickImage} />
						</>
					) : (
						<>
							<Text>&nbsp;</Text>
							<Button title="Take a photo" onPress={takeImage} />
						</>
					)}
				</View>

				<Modal
					animationType="slide"
					transparent={true}
					visible={modalVisible}
					onRequestClose={() => {
						setModalVisible(!modalVisible);
					}}
				>
					<View style={styles.centeredView}>
						<View style={styles.modalView}>{error ? errorModal() : successModal()}</View>
					</View>
				</Modal>
			</View>
		</ImageBackground>
	);
};

export default PhotoScreen;

const styles = StyleSheet.create({
	image: {
		flex: 1,
		resizeMode: 'contain',
		marginTop: '16px',
		marginBottom: '16px'
	},
	container: {
		padding: '20px',
		justifyContent: 'space-between',
		flexDirection: 'column',
		flex: 1
	},
	spinnerTextStyle: {
		color: '#FFF'
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
	},
	button: {
		borderRadius: 20,
		padding: 10,
		elevation: 2
	},
	buttonOpen: {
		backgroundColor: '#F194FF'
	},
	buttonClose: {
		backgroundColor: '#2196F3'
	},
	textStyle: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center'
	}
});
