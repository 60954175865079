import React, { useState, useEffect } from 'react';
import { View, Text, Button, StyleSheet, StatusBar } from 'react-native';
import { useTheme } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { useApp } from './../components/appContext';

import axios from 'react-native-axios';
var config = require('./../config');

import Spinner from 'react-native-loading-spinner-overlay';
import socket from '../components/socket';

const HomeScreen = ({ navigation, ...props }) => {
	const { colors } = useTheme();

	const { app, setApp } = useApp();
	const theme = useTheme();

	const [spinner, setSpinner] = useState(false);

	const [gamecode, setGamecode] = useState(null);

	Linking.getInitialURL().then((url) => {
		let gamecodeIndex = -1;
		let gamecode = '';
		if (url) {
			let cleanUrl = url.substring(url.indexOf('//') + 2);
			gamecodeIndex = cleanUrl.indexOf('?gamecode=');
			if (gamecodeIndex >= 0) gamecode = cleanUrl.substring(gamecodeIndex + 10);
		}
		if (gamecode.length > 0) setGamecode(gamecode);
	});

	useEffect(() => {
		if (!gamecode) return;
		socket.joinRoom(gamecode);
		setSpinner(true);

		const funct = async () => {
			const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
			setApp({ ...app, gamecode: gamecode, task: result.data });

			setSpinner(false);
			navigation.navigate('GameNav', { screen: 'MainScreen', params: { gamecode: gamecode } });
		};
		funct();
	}, [gamecode]);

	return (
		<View style={styles.container}>
			<StatusBar barStyle={theme.dark ? 'light-content' : 'dark-content'} />

			<Spinner visible={spinner} />
			{/* <Text style={{color: colors.text}}>Home Screen</Text> */}
			{/* <Button
        title="Scan code"
        onPress={() => navigation.navigate("ScannerScreen")}
      /> */}
			<Button title="Type code" onPress={() => navigation.navigate('TypeScreen')} />
		</View>
	);
};

export default HomeScreen;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center'
	}
});
