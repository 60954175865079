import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, StatusBar, TextInput, Text, Button, Image, Modal, Pressable, ImageBackground } from 'react-native';
import { useTheme } from '@react-navigation/native';

var config = require('./../../config');
import axios from 'react-native-axios';

import Spinner from 'react-native-loading-spinner-overlay';
import { useApp } from '../../components/appContext';

import { Audio } from 'expo-av';
import socket from '../../components/socket';

const QuestionScreen = ({ navigation, ...props }) => {
	const { colors } = useTheme();

	const theme = useTheme();

	const [question, setQuestion] = useState(null);
	const [text, setText] = useState('');
	const [textVersion, setTextVersion] = useState(1);

	const [loading, setLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [spinner, setSpinner] = useState(false);

	const [error, setError] = useState(false);

	const [submitResult, setSubmitResult] = useState(null);
	const { app, setApp } = useApp();
	const gamecode = app.gamecode;

	const [sound, setSound] = useState();

	async function playSound(sound_file) {
		console.log('Loading Sound');
		const { sound } = await Audio.Sound.createAsync(`http://www.surveysays.online/assets/${sound_file}`);
		setSound(sound);

		console.log('Playing Sound');
		await sound.playAsync();
	}

	useEffect(() => {
		if (modalVisible) socket.send('modal', { text: submitResult.message });
		else socket.send('modal', { text: null });
	}, [modalVisible]);

	const onChangeText = (str) => {
		setText(str);
		socket.send('answer_text', {
			game_id: question.game_id,
			answer_text: str,
			answer_text_version: textVersion
		});
		setTextVersion(textVersion + 1);
	};

	// React.useEffect(() => {
	//   return sound
	//     ? () => {
	//         console.log('Unloading Sound');
	//         sound.unloadAsync(); }
	//     : undefined;
	// }, [sound]);

	const requestQuestion = () => {
		(async () => {
			const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
			setQuestion(result.data);
			setApp({ ...app, task: result.data });
			setLoading(false);
		})();
	};

	const submitAnswer = () => {
		(async () => {
			setIsSubmitting(true);

			try {
				const result = await axios.post(`${config.apiLocation}/app/${gamecode}/submit`, {
					submitType: 'task',
					taskId: app?.task?.taskId,
					taskAnswer: text
				});

				setSubmitResult(result.data);
				if (result.data.sound) playSound(result.data.sound);
			} catch (error) {
				console.log(error);
				setError(true);
			}

			setIsSubmitting(false);
			setModalVisible(true);
		})();
	};

	const backButton = useCallback(async () => {
		try {
			setSpinner(true);
			await axios.post(`${config.apiLocation}/app/${gamecode}/action`, {
				action: 'room',
				taskId: null
			});

			const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
			setQuestion(result.data);
			setApp({ ...app, task: result.data });

			await navigation.goBack();
			setSpinner(false);
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		requestQuestion();
	}, []);

	const errorModal = () => {
		return (
			<>
				<Text style={styles.modalText}>Sorry, Something went wrong. Try again</Text>
				<Pressable
					style={[styles.button, styles.buttonOpen]}
					onPress={async () => {
						setModalVisible(false);
						setError(false);
					}}
				>
					<Text style={styles.textStyle}>Ok</Text>
				</Pressable>
			</>
		);
	};

	const successModal = () => {
		return (
			<>
				<Text style={styles.modalText}>{submitResult?.message} </Text>
				<Pressable
					style={[styles.button, styles.buttonOpen]}
					onPress={async () => {
						setLoading(true);
						setModalVisible(false);
						const result = await axios.get(`${config.apiLocation}/app/${gamecode}`);
						setApp({ ...app, task: result.data });

						await navigation.goBack();
						setLoading(false);
					}}
				>
					<Text style={styles.textStyle}>Ok</Text>
				</Pressable>
			</>
		);
	};

	if (!question) return <Spinner />;

	return (
		<ImageBackground source={{ uri: app?.task?.roomBackground }} style={{ flex: 1, resizeMode: 'cover' }}>
			<View style={{ padding: '20px', justifyContent: 'space-between', flexDirection: 'column', flex: 1 }}>
				<Spinner visible={isSubmitting || loading || spinner} />
				<View style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
					<Button style={{}} title="Back" onPress={backButton} />
					<Text
						style={{
							fontWeight: 'bold',
							fontSize: '1.2em',
							background: 'rgba(255,255,255,0.9)',
							borderRadius: '25px',
							padding: '5px',
							paddingRight: '12px',
							paddingLeft: '12px'
						}}
					>
						{question.taskTitle}
					</Text>
					<Text>&nbsp;</Text>
				</View>
				{/* <StatusBar barStyle={theme.dark ? 'light-content' : 'dark-content'} /> */}

				{question.taskImage && <Image style={styles.image} source={{ uri: question.taskImage }} />}
				<View style={{ textAlign: 'center', background: 'rgba(255,255,255,0.9)', borderRadius: '25px', padding: '10px', marginBottom: '20px' }}>
					<Text>{question.taskDescription}</Text>
					<Text>&nbsp;</Text>
					<TextInput style={styles.input} onChangeText={onChangeText} value={text} placeholder={'Fill in...'} />
					<Text>&nbsp;</Text>
				</View>
				<Button disabled={!text} title="Submit" onPress={() => submitAnswer()} />

				<Modal
					animationType="slide"
					transparent={true}
					visible={modalVisible}
					onRequestClose={() => {
						setModalVisible(!modalVisible);
					}}
				>
					<View style={styles.centeredView}>
						<View style={styles.modalView}>{error ? errorModal() : successModal()}</View>
					</View>
				</Modal>
			</View>
		</ImageBackground>
	);
};

export default QuestionScreen;

const styles = StyleSheet.create({
	image: {
		flex: 1,
		resizeMode: 'contain',
		marginTop: '16px',
		marginBottom: '16px'
	},
	input: {
		border: '1px solid #000000ad',
		padding: '5px'
	},
	// container: {
	// 	// flex: 1,
	// 	// alignItems: 'center',
	// 	// justifyContent: 'center',
	// 	padding: '20px'
	// },
	// imageContainer: {
	// 	flex: 1
	// },
	spinnerTextStyle: {
		color: '#FFF'
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
	},
	button: {
		borderRadius: 20,
		padding: 10,
		elevation: 2,
		marginTop: '20px'
	},
	buttonOpen: {
		backgroundColor: '#F194FF',
		color: '#000'
	},
	buttonClose: {
		backgroundColor: '#2196F3'
	},
	textStyle: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center'
	}
});
